<template>
    <div class="h-100">
        <div class="d-flex flex-column p-3"  v-if="!processing">
            <div class="mt-5">
                <strong class="text-primary title">
                    {{ $t('Completa l\'iscrizione') }}
                </strong>
            </div>
            <div class="description mt-3">
                {{ $t('Toccando iscriviti, accetti di essere rappresentata da')}}
                <a href="#"> {{ $t('Confestetica' )}}</a>
                {{ $t('e le nostre')}}
                <a href="https://www.iubenda.com/termini-e-condizioni/62789847" target="_blank"> {{ $t('condizioni' )}}</a>.
                {{ $t('Scopri in che modo raccogliamo, usiamo e condividiamo i tuoi dati nella nostra') }}
                <a href="https://www.iubenda.com/privacy-policy/62789847" target="_blank"> {{ $t('Normativa sui dati' )}}</a>
                {{ $t('e in che modo usiamo i cookie e tecnologie simili nella nostra')}}
                <a href="https://www.iubenda.com/privacy-policy/62789847/cookie-policy" target="_blank"> {{ $t('Normativa sui cookie' )}}</a>
                {{ $t('Potresti ricevere notifiche SMS da noi, ma puoi disattivarle in qualsiasi momento.') }}
            </div>
            <button class="btn btn-brand mt-3 w-100" @click.prevent="register">
                {{ $t('Iscriviti') }}
            </button>
        </div>
        <div v-else class="d-flex justify-content-center align-items-center flex-column h-100">
            <div class="text-brand font-size-xl font-weight-bold mb-2">{{ $t('Creazione del tuo account...')}}</div>
            <i class="text-grey fa-spin">
                <img alt="loading" src="~/assets/images/icons/loading.svg" width="36px">
            </i>
        </div>
    </div>
</template>

<script>

    import { mapActions } from 'vuex'
    import { mapFields } from 'vuex-map-fields'

    export default {

        computed: {
            ...mapFields('register', [
                'data.email',
                'data.password',
                'processing'
            ])
        },

        methods: {
            ...mapActions({
                'processRegistration': 'register/processRegistration',
                'login': 'user/login'
            }),
            register() {

                let _self = this;

                this.processing = true;

                this.processRegistration()
                    .then(() => {

                        this.login({
                            username: this.email,
                            password: this.password
                        })
                            .then(() => {

                                setTimeout(() => {
                                    _self.$router.push('/aggiorna-profilo?step=confirm-email')
                                }, 2000)
                            })
                    })
                    .catch(() => {

                        this.processing = false;
                    })
            }
        }
    }
</script>